<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-07-27 09:42:32
 * @LastEditTime: 2022-09-21 16:42:56
 * @Description: 全局设置
 * @Param:
 * @FilePath: \BusinessPlatform\src\views\admin\globalSettings\index.vue
 -->
<template>
    <div class="globalSettings">
        <div class="globalSettingsBox">
            <ul class="globalSettingsNav">
                <li class="active">
                    <i class="iconfont iconshijianxiao"></i>
                    <span>时间设置</span>
                </li>
            </ul>
            <div class="globalSettingsContent">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'global-settings',
    components: {},
    props: {},
    data() {
        return {

        };
    },
    watch: {},
    computed: {},
    created() {
        this.$router.push('/admin/globalSettings/timeSetting');
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped lang="stylus">
.globalSettings
    width 100%
    padding 0.14rem
    .globalSettingsBox
        width 100%
        height 100%
        background #fff
        display flex
        .globalSettingsNav
            width 1.6rem
            height 100%
            background #F4F7FD
            border 2px solid #fff
            padding 0.2rem 0
            li
                height 0.6rem
                font-size 0.18rem
                display flex
                justify-content center
                align-items center
                cursor pointer
                border-radius: 2px;
                border-top: 2px solid #FFFFFF;
                border-bottom: 2px solid #FFFFFF;
                i
                    font-size 0.2rem
                    margin-right 0.1rem
                &.active
                    position: relative
                    z-index 2
                    color #FFFFFF
                    border none
                    background: linear-gradient(270deg, #55A7FE 0%, #3E79C5 100%);
                    box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.4);
        .globalSettingsContent
            width calc(100% - 1.6rem)
            height 100%
</style>
